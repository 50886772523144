<script>
  import { language, width, height } from "./stores";

  let widthValue;
  let SVGwidth;
  let fontSize;
  width.subscribe(value => {
    widthValue = value;
    if (value > 1500) {
      widthValue = 1500;
    }
    SVGwidth = 0.1 * widthValue;
    fontSize = 0.015 * widthValue;
  });
</script>

<div class="container" style="width: {$width}px; height: {$height}px;">

<div class="content" style="width: {$width}px; height: {$height}px;">
<div class="star">
<svg width="{SVGwidth}" height="{SVGwidth}" viewBox="0 0 219 219" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M109.5 72.91L153.61 3L135.37 83.63L216 65.39L146.09 109.5L216 153.61L135.37 135.37L153.61 216L109.5 146.09L65.39 216L83.63 135.37L3 153.61L72.91 109.5L3 65.39L83.63 83.63L65.39 3L109.5 72.91Z" fill="white" stroke="black" stroke-miterlimit="10"/>
</svg>
</div>
{#if $language == "russian"}
<p style="font-size: {fontSize}px;">Загрузка...</p>
{:else if $language == "deutsch"}
<p style="font-size: {fontSize}px;">Bau!Schule wird geladen</p>
{/if}
</div>
</div>

<style>
  .container {
    background: #a5c500;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
  }
  .content {
    background: #a5c500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: Goethe, sans-serif;
    font-size: 29px;
    z-index: 11;
  }
  .star {
    animation: 5s rotating infinite linear;
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>