<script>
  import { width, height } from "./stores";

  let widthValue,
    heightValue,
    SVGwidth,
    SVGheight,
    logoSVGwidth,
    logoSVGheight,
    textMarginTop,
    fontSize;
  width.subscribe(value => {
    widthValue = value;
  });
  height.subscribe(value => {
    heightValue = value;
  });
  if (widthValue < heightValue) {
    SVGwidth = 0.8 * widthValue;
    SVGheight = 1.37 * SVGwidth;
    logoSVGwidth = 0.425 * widthValue;
    logoSVGheight = 0.1 * widthValue;
    textMarginTop = 0.75 * widthValue;
    fontSize = "12px";
  } else {
    SVGwidth = 0.7 * heightValue;
    SVGheight = 1.37 * SVGwidth;
    logoSVGwidth = 0.425 * heightValue;
    logoSVGheight = 0.1 * heightValue;
    textMarginTop = 0.75 * heightValue;
    fontSize = "10px";
  }
</script>

<div class="container">
<div class="logos">
<svg width="{logoSVGwidth}" height="{logoSVGheight}" viewBox="0 0 429 104" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M350.543 80.7144C350.543 80.6268 350.554 80.5174 350.554 80.4298C350.554 77.4189 347.784 74.9883 344.379 74.9883C341.609 74.9883 339.255 76.5977 338.466 78.8203C337.689 76.5977 335.313 74.9883 332.554 74.9883C329.149 74.9883 326.379 77.4189 326.379 80.4298C326.379 80.5174 326.379 80.6268 326.39 80.7144C326.39 80.7144 325.952 85.2909 338.466 92.7579C350.981 85.2909 350.543 80.7144 350.543 80.7144Z" fill="white"/>
<path d="M370.499 76.335H370.455C362.474 76.3678 355.992 83.8129 355.992 83.8129C355.992 83.8129 362.485 91.2909 370.488 91.3128H370.554C378.557 91.2799 385.017 83.8129 385.017 83.8129C385.017 83.8129 378.525 76.3459 370.51 76.335H370.499ZM370.499 90.0865C366.58 90.0865 363.393 87.2727 363.393 83.8239C363.393 80.375 366.569 77.5613 370.499 77.5613C374.43 77.5613 377.605 79.9918 377.605 83.8239C377.605 87.2946 374.43 90.0865 370.499 90.0865Z" fill="white"/>
<path d="M374.388 83.8129C374.388 85.718 372.636 87.2508 370.479 87.2508C368.322 87.2508 366.57 85.718 366.57 83.8129C366.57 81.9078 368.322 80.375 370.479 80.375C372.636 80.375 374.388 81.9078 374.388 83.8129Z" fill="white"/>
<path d="M320.006 81.9846C320.006 77.4847 315.594 73.8169 310.152 73.8169C304.711 73.8169 300.298 77.4628 300.298 81.9846C300.298 82.3459 300.331 82.7182 300.386 83.0685C300.397 83.1561 300.419 83.2218 300.43 83.2985C300.463 83.5722 300.419 83.605 300.419 83.605C300.419 83.6707 298.459 87.0429 298.317 87.4809C298.174 87.9407 298.656 88.0173 300.189 88.0392C300.189 88.0392 300.145 91.7508 300.189 92.8785C300.2 94.8602 308.871 92.8128 309.922 92.5391C311.258 92.1888 311.324 91.3348 311.379 90.8311C311.444 90.3932 311.455 90.1194 312.123 89.9771C312.156 89.9771 312.2 89.9662 312.233 89.9662C312.243 89.9662 312.265 89.9661 312.276 89.9552C316.7 89.145 320.006 85.8823 320.006 81.9846ZM314.663 86.7473H313.568V85.2692C312.243 85.4225 310.744 85.313 309.233 84.9298C305.094 83.8678 302.39 81.0649 303.178 78.6671C303.966 76.2694 307.974 75.1855 312.09 76.2475C316.229 77.3095 318.933 80.1124 318.145 82.5101C317.718 83.7692 316.415 84.6889 314.663 85.094V86.7363V86.7473Z" fill="white"/>
<path d="M307.262 30.0108H301.152V15.3506H314.433V19.2154H307.273V30.0108H307.262Z" fill="white"/>
<path d="M333.425 16.7741C335.068 17.9894 336.228 19.8616 336.228 22.6864C336.228 25.5111 335.068 27.3834 333.425 28.5987C331.553 29.9782 328.696 30.5256 326.188 30.5256C323.681 30.5256 320.824 29.9782 318.951 28.5987C317.309 27.3834 316.148 25.5111 316.148 22.6864C316.148 19.8616 317.309 17.9894 318.951 16.7741C320.824 15.3946 323.681 14.8472 326.188 14.8472C328.685 14.8472 331.542 15.3946 333.425 16.7741ZM323.506 19.5441C322.597 20.4748 322.477 21.7995 322.477 22.6973C322.477 23.5951 322.597 24.9199 323.506 25.8506C324.141 26.5184 325.115 26.8578 326.166 26.8578C327.218 26.8578 328.192 26.5075 328.827 25.8506C329.758 24.9199 329.856 23.5951 329.856 22.6973C329.856 21.7995 329.736 20.4748 328.827 19.5441C328.192 18.8762 327.218 18.5369 326.166 18.5369C325.137 18.5369 324.141 18.8872 323.506 19.5441Z" fill="white"/>
<path d="M345.461 30.0108H339.352V15.3506H345.461V20.4636H351.505V15.3506H357.614V30.0108H351.505V24.2627H345.461V30.0108Z" fill="white"/>
<path d="M366.096 24.8758V24.9963C366.096 25.478 366.217 26.0583 366.874 26.5072C367.509 26.9232 368.669 26.9889 369.25 26.9889C369.731 26.9889 370.761 26.9561 371.33 26.7043C372.042 26.3867 372.261 25.8393 372.261 25.4451C372.261 24.5802 371.56 24.2846 371.166 24.1642C370.618 23.9999 369.95 23.9671 368.91 23.9671H367.881V21.0766H369.064C369.523 21.0766 370.542 21.0109 371.089 20.8467C371.505 20.6934 371.987 20.4307 371.987 19.7847C371.987 19.1387 371.549 18.7993 371.089 18.6022C370.739 18.4599 370.126 18.2847 369.064 18.2847C368.002 18.2847 367.421 18.4818 367.005 18.7446C366.655 18.9745 366.228 19.4124 366.195 20.1898H360.655C360.622 18.6132 361.29 17.4964 361.947 16.8395C362.954 15.8103 364.969 14.814 369.217 14.814C371.275 14.814 373.837 15.0986 375.359 15.7775C376.936 16.4453 377.932 17.5073 377.932 19.3467C377.932 20.354 377.549 21.0219 377.187 21.4051C376.728 21.8649 375.972 22.1167 375.392 22.2153V22.3029C376.29 22.4233 377.067 22.8175 377.582 23.3321C378.293 24.0328 378.512 24.8649 378.512 25.7517C378.512 27.7772 377.614 29.0035 375.906 29.7699C374.779 30.2516 373.049 30.5801 369.502 30.5801C365.746 30.5801 363.677 30.0326 362.396 29.0691C361.761 28.5874 360.436 27.3064 360.524 24.8867H366.075L366.096 24.8758Z" fill="white"/>
<path d="M397.925 16.7741C399.568 17.9894 400.728 19.8616 400.728 22.6864C400.728 25.5111 399.568 27.3834 397.925 28.5987C396.053 29.9782 393.196 30.5256 390.688 30.5256C388.181 30.5256 385.323 29.9782 383.451 28.5987C381.809 27.3834 380.648 25.5111 380.648 22.6864C380.648 19.8616 381.809 17.9894 383.451 16.7741C385.323 15.3946 388.181 14.8472 390.688 14.8472C393.196 14.8472 396.053 15.3946 397.925 16.7741ZM388.028 19.5441C387.097 20.4748 386.999 21.7995 386.999 22.6973C386.999 23.5951 387.119 24.9199 388.028 25.8506C388.663 26.5184 389.637 26.8578 390.688 26.8578C391.739 26.8578 392.714 26.5075 393.349 25.8506C394.279 24.9199 394.378 23.5951 394.378 22.6973C394.378 21.7995 394.258 20.4748 393.349 19.5441C392.714 18.8762 391.739 18.5369 390.688 18.5369C389.637 18.5369 388.663 18.8872 388.028 19.5441Z" fill="white"/>
<path d="M404.383 19.3354H416.919V23.8025H404.383V19.3354Z" fill="white"/>
<path d="M301.163 56.364C301.94 55.2035 302.674 53.7035 303.035 52.3459C303.418 50.9335 303.495 49.4007 303.495 47.6599V45.3169H318.593V56.3422H320.936L320.619 63.7982H316.02V59.9771H303.977V63.7982H299.411L299.094 56.3422H301.163V56.364ZM308.586 48.9847V49.4664C308.586 50.627 308.466 52.0065 308.203 53.1342C307.885 54.4918 307.371 55.532 306.823 56.375H312.834V48.9847H308.586Z" fill="white"/>
<path d="M336.245 45.3281H342.508V59.9883H336.497V50.89L329.774 59.9883H323.512V45.3281H329.523V54.3936L336.245 45.3281Z" fill="white"/>
<path d="M351.014 54.8532V54.9737C351.014 55.4554 351.134 56.0357 351.791 56.4846C352.426 56.9007 353.587 56.9663 354.167 56.9663C354.649 56.9663 355.678 56.9335 356.247 56.6817C356.959 56.3642 357.178 55.8167 357.178 55.4225C357.178 54.5576 356.477 54.262 356.083 54.1416C355.536 53.9773 354.868 53.9445 353.828 53.9445H352.798V51.054H353.981C354.441 51.054 355.459 50.9883 356.006 50.8241C356.422 50.6708 356.904 50.4081 356.904 49.7621C356.904 49.1161 356.477 48.7767 356.006 48.5796C355.645 48.4264 355.032 48.2402 353.97 48.2402C352.908 48.2402 352.328 48.4373 351.912 48.7001C351.561 48.93 351.134 49.3679 351.101 50.1453H345.561C345.528 48.5687 346.196 47.4519 346.853 46.795C347.861 45.7658 349.875 44.7695 354.123 44.7695C356.182 44.7695 358.744 45.0542 360.265 45.733C361.842 46.4009 362.838 47.4628 362.838 49.3022C362.838 50.3095 362.455 50.9774 362.094 51.3606C361.634 51.8204 360.879 52.0723 360.298 52.1708V52.2584C361.196 52.3788 361.973 52.773 362.488 53.2875C363.2 53.9883 363.419 54.8204 363.419 55.7072C363.419 57.7327 362.521 58.9809 360.813 59.7254C359.685 60.2071 357.955 60.5356 354.408 60.5356C350.653 60.5356 348.583 59.9881 347.302 59.0246C346.667 58.5429 345.342 57.262 345.43 54.8423H350.992L351.014 54.8532Z" fill="white"/>
<path d="M366.364 50.233C366.397 48.9411 366.824 47.3097 368.674 46.1273C369.736 45.4266 371.477 44.8682 374.564 44.8682C376.623 44.8682 379.677 45.0652 381.375 46.1601C383.433 47.4849 383.717 49.3352 383.717 50.9775V57.3059C383.717 57.9738 383.685 58.565 383.947 59.0686C384.144 59.4847 384.407 59.616 384.648 59.6817V59.9992H378.276L377.926 58.4664H377.783C377.236 59.1781 376.524 59.7583 375.44 60.1416C374.433 60.4919 373.448 60.5576 372.32 60.5576C370.492 60.5576 368.685 60.4043 367.47 59.5722C366.659 58.9919 365.762 57.8971 365.762 56.1015C365.762 54.5468 366.462 53.5614 367.174 52.9812C368.532 51.8863 370.579 51.7987 372.342 51.6563L374.761 51.4593C375.309 51.3936 376.437 51.295 376.951 50.9775C377.499 50.66 377.586 50.1673 377.586 49.8827C377.586 49.6199 377.499 48.9849 376.721 48.6017C376.338 48.4046 375.758 48.2841 374.893 48.2841C374.181 48.2841 373.415 48.3717 372.867 48.7659C372.254 49.1929 372.156 49.7513 372.167 50.244H366.342L366.364 50.233ZM377.652 53.2549C377.455 53.4191 376.951 53.8023 375.495 53.9555L374.532 54.0431C373.634 54.1307 373.086 54.1855 372.659 54.4592C372.221 54.722 371.959 55.1709 371.959 55.7183C371.959 56.3862 372.397 56.7256 372.572 56.846C372.999 57.1307 373.634 57.1964 374.214 57.1964C374.991 57.1964 375.977 57.1088 376.754 56.2658C377.302 55.6855 377.652 54.7329 377.652 53.4081V53.2549Z" fill="white"/>
<path d="M400.37 45.3282H406.633V59.9884H400.622V50.8901L393.899 59.9884H387.637V45.3282H393.648V54.3936L400.37 45.3282ZM403.742 39.0327C403.863 40.6093 403.107 41.6056 402.647 42.0545C401.29 43.3793 398.925 43.4997 397.107 43.4997C395.29 43.4997 392.925 43.3793 391.567 42.0545C391.107 41.5946 390.352 40.6093 390.472 39.0327H394.775V39.1531C394.775 39.613 394.841 40.1604 395.388 40.5984C395.87 40.9816 396.647 41.0253 397.118 41.0253C397.589 41.0253 398.366 40.9597 398.848 40.5984C399.396 40.1714 399.461 39.613 399.461 39.1531V39.0327H403.731H403.742Z" fill="white"/>
<path d="M416.691 59.9991H410.582V45.3389H416.691V50.4519H422.735V45.3389H428.844V59.9991H422.735V54.2511H416.691V59.9991Z" fill="white"/>
<path d="M84.9178 4.22616C93.0417 4.22616 99.6327 10.8173 99.6327 18.9412C99.6327 27.0651 93.0417 33.6562 84.9178 33.6562C76.7939 33.6562 70.2027 27.0651 70.2027 18.9412C70.2027 10.8173 76.7939 4.22616 84.9178 4.22616ZM84.9178 37.8823C95.3738 37.8823 103.859 29.4081 103.859 18.9412C103.859 8.47425 95.3847 0 84.9178 0C74.4509 0 65.9766 8.47425 65.9766 18.9412C65.9766 29.4081 74.4509 37.8823 84.9178 37.8823Z" fill="white"/>
<path d="M37.4347 51.9406C37.4347 43.9371 43.9163 37.4555 51.9307 37.4555V33.3169C41.6281 33.3169 33.2852 41.6488 33.2852 51.9406C33.2852 62.2323 41.6281 70.5861 51.9307 70.5861C62.2334 70.5861 70.5544 62.2432 70.5544 51.9406H66.4487C66.4487 59.944 59.9342 66.4366 51.9307 66.4366C43.9273 66.4366 37.4347 59.955 37.4347 51.9406Z" fill="white"/>
<path d="M25.0273 51.9401C25.0273 66.7975 37.0709 78.841 51.9283 78.841C66.7856 78.841 78.8619 66.7975 78.8619 51.9401H74.7452C74.7452 64.5201 64.5083 74.7133 51.9392 74.7133C39.3702 74.7133 29.166 64.5201 29.166 51.9401C29.166 39.3601 39.3592 29.1669 51.9392 29.1669V25.0283C37.0819 25.0283 25.0383 37.0718 25.0383 51.9292L25.0273 51.9401Z" fill="white"/>
<path d="M16.6758 51.9405C16.6758 71.4073 32.4638 87.1952 51.9305 87.1952C71.3972 87.1952 87.1413 71.4182 87.1413 51.9405H82.9919C82.9919 69.0861 69.0652 82.98 51.9305 82.98C34.7959 82.98 20.8911 69.0861 20.8911 51.9405C20.8911 34.795 34.7849 20.9011 51.9305 20.9011V16.7188C32.4638 16.7188 16.6758 32.4629 16.6758 51.9296V51.9405Z" fill="white"/>
<path d="M51.9281 8.32129C27.8411 8.32129 8.30859 27.8537 8.30859 51.9408C8.30859 76.0278 27.8411 95.5602 51.9281 95.5602C76.0152 95.5602 95.5147 76.0387 95.5147 51.9408H91.3652C91.3652 73.7067 73.6941 91.3559 51.9281 91.3559C30.1622 91.3559 12.513 73.7067 12.513 51.9408C12.513 30.1748 30.1622 12.5256 51.9281 12.5256V8.32129Z" fill="white"/>
<path d="M51.9295 99.7311C25.5323 99.7311 4.13862 78.3374 4.13862 51.9402C4.13862 25.543 25.5323 4.1493 51.9295 4.1493V0.0107422C23.244 0.0107422 0 23.2547 0 51.9402C0 80.6257 23.255 103.87 51.9295 103.87C80.604 103.87 103.859 80.6257 103.859 51.9402H99.7532C99.7532 78.3265 78.3267 99.7311 51.9405 99.7311H51.9295Z" fill="white"/>
<path d="M128.164 45.3602C130.234 45.3602 132.785 44.9003 134.175 44.2434V32.9992H127.091V36.5356H129.905V41.5829C129.412 41.6705 128.668 41.7034 128.055 41.7034C124.923 41.7034 122.493 40.0501 122.493 34.729C122.493 29.408 124.945 27.8424 128.602 27.8424C130.124 27.8424 132.139 28.1708 133.42 28.6854L133.858 25.1162C132.445 24.5468 130.135 24.1855 128.361 24.1855C122.066 24.1855 118.004 27.4811 118.004 34.7838C118.004 42.0866 121.759 45.3492 128.164 45.3492V45.3602Z" fill="white"/>
<path d="M199.519 44.9443H203.909V24.5142H199.519V32.5833H192.95V24.5142H188.57V44.9443H192.95V36.2292H199.519V44.9443Z" fill="white"/>
<path d="M181.657 44.9443V28.1929H186.091V24.5142H172.777V28.1929H177.211V44.9443H181.657Z" fill="white"/>
<path d="M162.454 36.2511H169.669V32.649H162.454V28.16H170.95V24.5142H158.074V44.9443H171.235V41.2984H162.454V36.2511Z" fill="white"/>
<path d="M146.047 27.5578C148.828 27.5578 150.164 29.2987 150.164 34.7183C150.164 40.1379 148.828 41.9334 146.08 41.9334C143.332 41.9334 141.941 40.1379 141.941 34.7183C141.941 29.2987 143.299 27.5578 146.058 27.5578H146.047ZM146.047 45.5574C151.85 45.5574 154.653 42.437 154.653 34.7292C154.653 27.0213 151.85 23.9229 146.047 23.9229C140.244 23.9229 137.441 27.1746 137.441 34.7292C137.441 42.2837 140.354 45.5574 146.047 45.5574Z" fill="white"/>
<path d="M221.418 41.2984H212.637V36.2511H219.853V32.649H212.637V28.16H221.133V24.5142H208.258V44.9443H221.418V41.2984Z" fill="white"/>
<path d="M157.473 55.5098H161.535V71.6809H165.826V55.5098H169.888V51.9624H157.473V55.5098Z" fill="white"/>
<path d="M119.473 71.6809H123.797V51.9624H119.473V71.6809Z" fill="white"/>
<path d="M203.196 64.8161C203.196 67.1044 202.408 68.3635 200.284 68.3635C198.16 68.3635 197.339 67.0715 197.339 64.8161V51.9624H193.047V64.9803C193.047 69.7101 195.488 72.0203 200.273 72.0203C205.058 72.0203 207.444 69.7101 207.444 64.9803V51.9624H203.196V64.8161Z" fill="white"/>
<path d="M149.21 57.2174C149.21 56.1992 149.735 55.1919 151.881 55.1919C153.228 55.1919 154.421 55.5532 155.615 56.0678L156.086 52.619C154.717 51.984 153.305 51.6445 151.509 51.6445C146.954 51.6445 144.995 54.2722 144.995 57.6116C144.995 63.9508 152.834 62.7465 152.834 66.261C152.834 67.6187 151.98 68.5274 150.184 68.5274C148.389 68.5274 146.922 67.9361 145.465 67.104L144.918 70.6295C146.44 71.5273 148.323 72.02 150.381 72.02C154.64 72.02 157.049 69.6551 157.049 65.7902C157.049 59.2429 149.21 60.2612 149.21 57.2174Z" fill="white"/>
<path d="M138.076 63.4694L132.01 51.9624H128.102V71.6809H132.01V60.1629L138.076 71.6809H141.995V51.9624H138.076V63.4694Z" fill="white"/>
<path d="M209.438 51.9624V55.5098H213.499V71.6809H217.791V55.5098H221.853V51.9624H209.438Z" fill="white"/>
<path d="M172.082 71.6809H176.407V51.9624H172.082V71.6809Z" fill="white"/>
<path d="M178.594 55.5098H182.656V71.6809H186.948V55.5098H191.009V51.9624H178.594V55.5098Z" fill="white"/>
</svg>
</div>

<div class="content" style="width: {SVGwidth}px; height: {SVGheight}px;">
<div class="text" style="margin-top: {textMarginTop}px; font-size: {fontSize};">
<h2>
Привет! Рады тебя видеть :) 
<br>Открой, пожалуйста, игру на компьютере!
</h2>
</div>
<div class="SVGbg">
<svg width="{SVGwidth}" height="{SVGheight}" viewBox="0 0 1011 1386" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M368.486 594.643L159.855 726.179L265.952 503.581L33 422.536L273.234 366.665L191.387 134.074L384.856 286.995L515.729 78L516.757 324.565L761.81 296.546L569.62 451.081L744.317 625.148L503.628 571.273L476.425 816.344L368.486 594.643Z" fill="#A5C500"/>
<path d="M188 841L149.332 825.233C141.067 821.859 134.51 815.257 131.159 806.935L115.5 768L99.8406 806.935C96.4898 815.257 89.9334 821.859 81.6682 825.233L43 841L81.6682 856.767C89.9334 860.141 96.4898 866.743 99.8406 875.065L115.5 914L131.159 875.065C134.51 866.743 141.067 860.141 149.332 856.767L188 841Z" fill="#9AD8F6"/>
<path d="M908.419 120.762L847.441 96.0688C831.475 89.6029 818.816 76.9437 812.35 60.9777L787.657 0L762.963 60.9777C756.497 76.9437 743.838 89.6029 727.872 96.0688L666.895 120.762L727.872 145.456C743.838 151.921 756.497 164.581 762.963 180.547L787.657 241.524L812.35 180.547C818.816 164.581 831.475 151.921 847.441 145.456L908.419 120.762Z" fill="#9AD8F6"/>
<path d="M463.124 1357.74L435.841 1365.46C430.399 1366.99 425.99 1360.94 429.12 1356.23L444.822 1332.62C446.183 1330.58 446.183 1327.91 444.822 1325.87L429.052 1302.31C425.908 1297.61 430.303 1291.54 435.746 1293.07L463.056 1300.71C465.423 1301.38 467.954 1300.55 469.478 1298.62L487.018 1276.33C490.515 1271.89 497.645 1274.19 497.876 1279.84L499.046 1308.17C499.142 1310.63 500.72 1312.78 503.02 1313.63L529.635 1323.41C534.942 1325.36 534.955 1332.85 529.649 1334.83L503.06 1344.69C500.761 1345.55 499.196 1347.7 499.101 1350.16L498.012 1378.49C497.795 1384.14 490.664 1386.47 487.167 1382.03L469.573 1359.8C468.049 1357.86 465.505 1357.05 463.151 1357.71L463.124 1357.74Z" fill="#FEF501" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
<path d="M473.508 1315.91C474.781 1311.86 474.819 1308.25 473.593 1307.87C472.367 1307.48 470.341 1310.46 469.067 1314.52C467.794 1318.58 467.756 1322.18 468.982 1322.57C470.208 1322.95 472.234 1319.97 473.508 1315.91Z" fill="black"/>
<path d="M490.199 1321.15C491.472 1317.09 491.511 1313.49 490.285 1313.1C489.058 1312.72 487.032 1315.69 485.759 1319.75C484.485 1323.81 484.447 1327.41 485.673 1327.8C486.899 1328.18 488.926 1325.2 490.199 1321.15Z" fill="black"/>
<path d="M489.946 1333.24C487.47 1341.14 479.061 1345.52 471.155 1343.05C463.249 1340.57 458.868 1332.16 461.344 1324.25" stroke="black" stroke-miterlimit="10"/>
<g clip-path="url(#clip0_667_1366)">
<path d="M180.582 647.951C181.427 649.569 183.044 650.746 185.452 651.483C186.773 651.887 188.031 652.004 189.235 651.815C190.43 651.636 191.625 651.177 192.811 650.44C195.812 652.804 199.451 655.176 203.729 657.539C201.455 660.217 198.49 661.942 194.815 662.697C191.14 663.46 186.8 663.065 181.786 661.538C175.092 659.489 170.617 656.407 168.371 652.291C166.106 648.194 165.765 643.594 167.328 638.499C168.883 633.404 171.749 629.792 175.91 627.644C180.079 625.497 185.506 625.452 192.2 627.501C197.214 629.037 201.024 631.149 203.648 633.835C206.262 636.522 207.763 639.613 208.14 643.108C204.34 642.776 200 642.695 195.12 642.866C194.545 641.59 193.809 640.547 192.928 639.73C192.038 638.912 190.933 638.292 189.612 637.888C187.204 637.151 185.2 637.214 183.601 638.085C181.993 638.957 180.843 640.395 180.133 642.416C179.585 644.483 179.737 646.325 180.573 647.943L180.582 647.951Z" fill="white"/>
<path d="M209.319 664.593C206.902 660.549 206.381 655.931 207.755 650.737C209.103 645.669 211.817 641.985 215.923 639.694C220.021 637.394 225.538 637.169 232.475 639.002C237.138 640.242 240.831 642.03 243.572 644.384C246.312 646.738 248.091 649.398 248.909 652.372C249.727 655.347 249.709 658.429 248.864 661.636C247.48 666.866 244.632 670.604 240.328 672.841C236.024 675.088 230.363 675.276 223.354 673.416C216.426 671.574 211.745 668.636 209.328 664.593H209.319ZM232.726 662.831C234.352 661.915 235.449 660.405 236.006 658.303C236.536 656.317 236.32 654.511 235.359 652.893C234.397 651.276 232.6 650.117 229.968 649.416C227.335 648.715 225.241 648.832 223.696 649.767C222.15 650.701 221.108 652.184 220.569 654.205C220.012 656.317 220.173 658.159 221.054 659.731C221.935 661.313 223.696 662.445 226.328 663.146C228.961 663.847 231.091 663.739 232.726 662.822V662.831Z" fill="white"/>
<path d="M279.854 668.241C282.595 668.861 284.464 669.948 285.47 671.502C286.476 673.066 286.782 674.701 286.396 676.417C285.578 680.057 283.278 682.51 279.513 683.759C275.748 685.017 271.264 685.052 266.07 683.884C261.533 682.86 257.705 681.18 254.605 678.844C251.496 676.507 249.582 673.326 248.863 669.292L251.649 669.211C255.665 669.13 258.693 668.87 260.724 668.42C261.093 669.84 261.793 671.017 262.827 671.961C263.86 672.904 265.361 673.596 267.319 674.036C269.53 674.539 271.264 674.71 272.522 674.566C273.78 674.423 274.517 673.875 274.732 672.94C274.894 672.203 274.355 671.547 273.115 670.963C271.866 670.379 270.024 669.813 267.571 669.256L264.076 668.465L265.909 660.369L267.993 660.837C270.24 661.34 272.064 661.645 273.466 661.753C274.858 661.852 275.64 661.555 275.793 660.864C275.946 660.172 275.685 659.525 275.002 658.941C274.319 658.357 273.322 657.916 272.01 657.62C269.88 657.144 268.128 657.153 266.744 657.656C265.361 658.159 263.941 659.174 262.476 660.693C259.547 658.617 256.231 656.856 252.511 655.409C257.238 649.129 264.57 647.107 274.517 649.353C277.419 650.009 279.98 651.024 282.208 652.408C284.428 653.792 286.072 655.364 287.141 657.135C288.202 658.905 288.534 660.684 288.13 662.481C287.24 666.408 283.916 668.214 278.138 667.899C278.551 667.953 279.135 668.061 279.872 668.223L279.854 668.241Z" fill="white"/>
<path d="M328.655 686.625L330.506 686.966L327.415 703.599L315.725 701.424L316.974 694.685L297.431 691.055L296.155 697.92L284.465 695.745L287.574 678.987L289.614 679.365C291.051 679.419 292.21 679.104 293.082 678.412C293.954 677.72 294.771 676.462 295.544 674.647C296.11 673.219 296.91 669.696 297.952 664.089L299.569 655.374L333.265 661.699L328.637 686.625H328.655ZM304.97 682.222L316.84 684.424L319.535 669.894L309.022 667.944L308.681 669.795C308.195 672.176 307.782 674.054 307.441 675.42C307.099 676.786 306.569 678.403 305.832 680.272C305.662 680.713 305.374 681.36 304.961 682.222H304.97Z" fill="white"/>
<path d="M374.184 691.872C373.978 693.283 373.996 694.308 374.247 694.937C374.499 695.566 375.101 696.078 376.062 696.473L374.966 702.287C373.609 702.305 372.432 702.287 371.444 702.224C370.455 702.161 369.152 702.017 367.535 701.783C363.968 701.262 361.712 700.121 360.787 698.351C359.861 696.581 359.574 694.46 359.933 691.962L360.158 690.408C358.46 693.634 356.339 695.916 353.796 697.237C351.253 698.558 348.198 698.962 344.622 698.45C340.471 697.848 337.47 696.563 335.619 694.595C333.768 692.627 333.076 690.012 333.552 686.733C333.947 684.037 335.142 682.051 337.155 680.775C339.159 679.499 342.079 678.844 345.898 678.808C343.068 676.112 340.201 673.695 337.29 671.583C340.237 668.789 343.427 666.812 346.859 665.635C350.292 664.458 354.29 664.206 358.855 664.871C365.244 665.797 369.781 667.872 372.477 671.08C375.164 674.288 376.098 678.754 375.263 684.486L374.184 691.89V691.872ZM356.501 688.44C358.496 687.82 359.951 686.832 360.868 685.484L361.101 683.866C360.032 684.217 358.873 684.442 357.633 684.54C356.393 684.639 354.901 684.684 353.158 684.684L350.867 684.729C348.477 684.846 347.201 685.466 347.039 686.589C346.859 687.793 347.857 688.548 350.013 688.862C352.341 689.204 354.497 689.06 356.492 688.44H356.501ZM352.808 676.238C351.083 676.705 349.483 677.559 347.992 678.781C350.526 678.853 353.661 679.158 357.39 679.706C358.927 679.931 360.032 679.904 360.706 679.616C361.38 679.338 361.766 678.906 361.847 678.322C361.919 677.828 361.668 677.352 361.101 676.912C360.526 676.471 359.682 676.166 358.568 676.004C356.447 675.699 354.533 675.78 352.808 676.247V676.238Z" fill="white"/>
<path d="M421.313 672.94C420.46 678.96 419.777 684.415 419.265 689.294C418.753 694.092 418.285 699.564 417.845 705.71L405.023 704.353L408.006 682.788L396.145 703.419L378.695 701.568C379.531 696.087 380.232 690.642 380.807 685.223C381.391 679.715 381.84 674.243 382.164 668.798L394.483 670.101L391.509 692.241L403.432 671.053L421.322 672.949L421.313 672.94ZM396.001 667.414C394.447 666.363 393.279 664.979 392.488 663.245C391.697 661.52 391.419 659.57 391.652 657.404L397.843 658.06C397.673 659.687 397.969 660.927 398.742 661.789C399.515 662.652 400.566 663.155 401.905 663.299C403.244 663.443 404.376 663.173 405.31 662.49C406.245 661.807 406.802 660.657 406.973 659.031L413.164 659.687C412.93 661.852 412.256 663.703 411.124 665.231C409.992 666.758 408.554 667.872 406.82 668.573C405.077 669.274 403.226 669.526 401.267 669.31C399.308 669.103 397.547 668.474 395.992 667.423L396.001 667.414Z" fill="white"/>
<path d="M450.19 697.327C451.394 698.702 453.254 699.456 455.761 699.6C457.145 699.681 458.394 699.492 459.508 699.025C460.622 698.567 461.674 697.839 462.653 696.842C466.13 698.423 470.228 699.861 474.945 701.137C473.382 704.282 470.902 706.663 467.514 708.271C464.127 709.88 459.814 710.535 454.584 710.239C447.593 709.835 442.517 707.912 439.354 704.452C436.191 700.993 434.762 696.608 435.068 691.289C435.373 685.969 437.296 681.773 440.827 678.7C444.359 675.627 449.624 674.288 456.615 674.692C461.844 674.989 466.059 676.139 469.239 678.125C472.42 680.111 474.613 682.761 475.808 686.059C472.034 686.643 467.802 687.596 463.111 688.925C462.249 687.829 461.287 686.985 460.236 686.401C459.176 685.816 457.954 685.484 456.579 685.403C454.063 685.259 452.14 685.798 450.783 687.021C449.435 688.243 448.654 689.923 448.447 692.052C448.411 694.191 448.986 695.952 450.19 697.318V697.327Z" fill="white"/>
<path d="M516.972 701.649C516.873 704.92 515.561 707.4 513.009 709.089C510.466 710.778 505.946 711.524 499.45 711.344L478.199 710.733C478.55 705.962 478.819 700.238 479.008 693.58C479.197 686.913 479.26 681.189 479.188 676.409L492.199 676.786V676.849L500.366 677.083C504.302 677.199 507.384 677.613 509.612 678.323C511.832 679.041 513.395 680.012 514.276 681.261C515.165 682.501 515.579 684.064 515.525 685.951C515.399 690.183 512.362 693.032 506.405 694.496C513.575 694.703 517.088 697.093 516.954 701.658L516.972 701.649ZM496.242 702.556C498.632 702.628 500.268 702.494 501.166 702.161C502.056 701.829 502.514 701.2 502.541 700.283C502.568 699.483 502.119 698.89 501.211 698.486C500.303 698.082 498.695 697.848 496.386 697.776L491.606 697.641L491.471 702.422L496.251 702.556H496.242ZM491.956 685.439L491.83 689.842L496.61 689.977C498.542 690.031 499.908 689.896 500.717 689.563C501.526 689.231 501.939 688.602 501.966 687.676C501.984 686.967 501.588 686.445 500.78 686.131C499.971 685.816 498.623 685.628 496.736 685.574L491.956 685.439Z" fill="white"/>
<path d="M525.498 707.318C522.065 704.084 520.304 699.789 520.214 694.415C520.125 689.177 521.742 684.891 525.058 681.566C528.373 678.241 533.621 676.516 540.791 676.39C545.616 676.31 549.66 677.028 552.94 678.547C556.219 680.065 558.654 682.141 560.245 684.774C561.844 687.407 562.671 690.381 562.725 693.696C562.815 699.106 561.098 703.473 557.567 706.806C554.036 710.14 548.645 711.865 541.393 711.991C534.223 712.117 528.921 710.562 525.489 707.327L525.498 707.318ZM547.539 699.24C548.86 697.92 549.498 696.167 549.462 693.984C549.426 691.926 548.725 690.255 547.36 688.952C545.994 687.658 543.954 687.029 541.223 687.074C538.5 687.119 536.514 687.802 535.283 689.123C534.052 690.444 533.45 692.151 533.486 694.245C533.522 696.428 534.178 698.153 535.463 699.429C536.739 700.705 538.743 701.325 541.474 701.28C544.197 701.235 546.218 700.552 547.539 699.223V699.24Z" fill="white"/>
<path d="M612.442 677.873C615.461 680.838 617.133 685.106 617.474 690.677C617.815 696.329 616.683 700.786 614.06 704.057C611.445 707.327 607.096 709.142 601.031 709.511C595.963 709.816 591.964 708.936 589.026 706.869C586.088 704.802 584.111 701.711 583.105 697.614L579.151 697.857L579.358 701.307C579.492 703.571 579.717 706.582 580.032 710.337L566.913 711.137C566.562 703.931 566.248 698.09 565.978 693.615C565.7 689.096 565.322 683.444 564.828 676.669L577.947 675.869C578.154 680.649 578.351 684.666 578.549 687.928L582.565 687.685C583.078 683.624 584.668 680.398 587.337 678.008C590.005 675.618 593.851 674.27 598.874 673.964C604.903 673.596 609.423 674.899 612.442 677.864V677.873ZM604.517 697.317C605.478 695.916 605.883 693.939 605.73 691.387C605.577 688.88 604.939 686.984 603.807 685.708C602.684 684.432 601.237 683.848 599.476 683.956C597.679 684.064 596.304 684.819 595.361 686.22C594.417 687.622 594.022 689.581 594.175 692.088C594.327 694.64 594.965 696.554 596.098 697.83C597.221 699.106 598.667 699.69 600.429 699.582C602.19 699.474 603.547 698.719 604.517 697.317Z" fill="white"/>
<path d="M677.757 666.659C678.296 673.012 678.862 678.709 679.456 683.75C680.049 688.79 680.821 694.46 681.783 700.768L635.436 706.24C635.085 701.469 634.519 695.772 633.737 689.15C632.956 682.528 632.183 676.858 631.41 672.131L644.277 670.613L646.964 693.346L651.771 692.78L649.085 670.047L660.137 668.744L662.823 691.477L667.568 690.92L664.881 668.187L677.748 666.668L677.757 666.659Z" fill="white"/>
<path d="M721.238 682.995C722.532 684.702 723.817 686.787 725.111 689.258C726.342 691.396 727.205 692.789 727.69 693.427L712.72 695.826C712.549 695.557 712.154 694.685 711.534 693.212C710.959 691.738 710.429 690.552 709.944 689.68C709.459 688.8 708.838 688.063 708.057 687.47C706.844 686.517 705.568 685.978 704.229 685.852C702.89 685.727 700.958 685.87 698.433 686.275L700.284 697.83L686.995 699.96C686.447 695.206 685.638 689.536 684.587 682.959C683.526 676.373 682.52 670.739 681.559 666.057L694.848 663.928L696.798 676.103L697.05 676.067C701.183 670.352 704.121 665.716 705.837 662.176L722.236 659.543L710.025 676.346C712.325 676.912 714.356 677.694 716.135 678.682C718.237 679.877 719.936 681.324 721.229 683.022L721.238 682.995Z" fill="white"/>
<path d="M731.67 688.224C727.717 685.655 725.219 681.737 724.168 676.471C723.143 671.332 723.97 666.83 726.639 662.957C729.307 659.094 734.168 656.461 741.195 655.059C745.921 654.116 750.037 654.107 753.532 655.014C757.027 655.922 759.795 657.53 761.835 659.839C763.874 662.149 765.222 664.925 765.869 668.178C766.92 673.479 766.013 678.089 763.138 681.997C760.262 685.906 755.257 688.566 748.15 689.986C741.114 691.387 735.624 690.803 731.67 688.224ZM751.915 676.346C752.975 674.809 753.298 672.976 752.867 670.838C752.463 668.825 751.474 667.297 749.902 666.264C748.329 665.231 746.209 664.979 743.531 665.509C740.854 666.039 739.029 667.064 738.05 668.591C737.071 670.11 736.783 671.898 737.196 673.955C737.619 676.094 738.58 677.675 740.063 678.709C741.554 679.742 743.63 679.985 746.308 679.455C748.976 678.924 750.845 677.891 751.915 676.355V676.346Z" fill="white"/>
<path d="M810.726 675.582L797.329 678.79L792.028 656.649L783.339 658.725L783.734 660.639C784.165 662.607 784.498 664.35 784.758 665.86C785.01 667.369 785.181 669.022 785.262 670.82C785.657 678.61 780.904 683.696 770.993 686.068L768.665 676.346C770.525 675.645 771.684 674.728 772.152 673.605C772.619 672.482 772.601 670.9 772.116 668.861L767.668 650.261L802.675 641.949L810.726 675.591V675.582Z" fill="white"/>
<path d="M845.984 629.819L845.57 633.719C844.591 645.013 843.333 655.985 841.796 666.65C840.97 672.105 838.912 676.876 835.614 680.955C832.317 685.035 827.537 687.883 821.292 689.509C819.584 686.373 818.219 683.938 817.185 682.204C816.152 680.47 814.966 678.763 813.627 677.091C815.99 676.993 818.147 676.678 820.088 676.13C823.241 675.249 825.551 673.884 827.042 672.033C824.383 668.124 821.364 663.955 817.994 659.543C814.624 655.131 809.862 649.174 803.707 641.662L818.066 637.645C819.117 639.532 820.438 641.68 822.028 644.115C823.619 646.541 825.191 648.868 826.728 651.096C827.186 651.797 827.905 652.849 828.884 654.25C829.864 655.652 830.663 656.829 831.283 657.791L831.517 652.561C832.092 642.956 832.272 636.675 832.056 633.728L845.984 629.828V629.819Z" fill="white"/>
</g>
<g clip-path="url(#clip1_667_1366)">
<path d="M0 593.942L0.205799 588.932C1.01527 569.304 1.42687 550.693 1.42687 533.601C1.42687 516.509 1.01527 497.899 0.205799 478.271L0 473.261H45.6873V473.412H73.4084C86.4149 473.412 96.9038 474.532 104.58 476.731C112.846 479.109 118.876 482.752 122.505 487.556C126.141 492.373 127.979 498.442 127.979 505.603C127.979 512.764 125.386 519.678 120.269 525.409C118.375 527.533 116.043 529.471 113.251 531.23C130.024 535.745 134.092 546.611 134.092 556.081C134.092 563.93 132.239 570.761 128.576 576.376C124.824 582.135 118.279 586.595 109.114 589.633C100.402 592.526 88.8227 593.928 73.7103 593.928H0V593.942ZM70.2048 556.329C75.2126 556.329 79.0336 556.116 81.5718 555.703C84.2266 555.27 84.9743 554.604 85.0498 554.535C85.1389 554.446 85.4065 554.191 85.4065 553.188C85.4065 552.83 85.4065 552.459 84.0619 551.91C82.7585 551.38 79.2257 550.501 70.2117 550.501H45.6942V556.329H70.2117H70.2048ZM70.2048 515.485C77.5518 515.485 80.3987 514.695 81.4483 514.228C81.9971 513.98 82.3538 513.822 82.3538 512.496C82.3538 512.468 82.3538 512.441 82.3538 512.42C82.2578 512.345 82.0382 512.2 81.5855 512.028C80.433 511.602 77.4215 510.881 70.2117 510.881H45.6942V515.485H70.2117H70.2048Z" fill="#FE86F6"/>
<path d="M224.136 594.704C213.653 594.704 206.684 592.127 202.842 586.829C201.491 584.966 200.4 582.911 199.57 580.664C197.43 583.406 195.022 585.75 192.353 587.674C185.898 592.333 177.687 594.698 167.939 594.698C156.695 594.698 147.963 592.292 141.974 587.536C135.649 582.52 132.445 575.125 132.445 565.565C132.445 557.504 135.217 551.03 140.684 546.315C143.723 543.697 147.578 541.601 152.339 540.006C147.777 537.092 143.209 534.433 138.681 532.041L133.57 529.347L137.151 524.804C143.661 516.544 151.228 510.166 159.645 505.836C168.131 501.472 178.476 499.266 190.391 499.266C207.205 499.266 220.02 503.101 228.478 510.661C237.094 518.365 241.457 530.096 241.457 545.532V563.737C241.457 567.579 242.013 568.637 242.04 568.685C242.04 568.685 242.458 569.104 243.926 569.469L247.727 570.417L246.78 592.656L242.891 593.268C239.577 593.79 236.614 594.162 234.082 594.381C231.544 594.594 228.197 594.704 224.129 594.704H224.136ZM176.397 560.115C176.74 560.136 177.131 560.15 177.563 560.15C182.29 560.15 186.598 559.284 190.378 557.579C188.546 557.909 186.55 558.232 184.416 558.541L179.038 559.442C177.934 559.662 177.069 559.895 176.404 560.115H176.397Z" fill="#FE86F6"/>
<path d="M279.682 595.467C272.72 595.467 266.546 593.873 261.325 590.725C256.077 587.564 252.05 583.221 249.354 577.819C246.741 572.575 245.41 566.658 245.41 560.218V538.192C245.41 524.804 245.156 513.939 244.655 505.912L244.34 500.799H288.806L288.6 505.809C287.997 520.667 287.688 535.574 287.688 550.13C287.688 553.449 288.422 556.019 289.869 557.772C291.145 559.318 292.572 560.005 294.493 560.005C295.577 560.005 297.237 559.744 299.165 557.483C300.866 555.49 301.943 552.569 302.368 548.796V545.415C302.574 537.505 302.67 531.986 302.67 529.017C302.67 522.124 302.464 514.324 302.066 505.843L301.826 500.806H348.433L348.152 505.884C347.445 518.578 347.088 532.488 347.088 547.229C347.088 561.971 347.445 576.087 348.152 588.877L348.447 594.196L343.137 593.949C336.723 593.646 331.612 593.495 327.942 593.495C324.471 593.495 319.354 593.646 312.741 593.949L308.611 594.134L306.224 582.025C304.413 584.575 302.361 586.76 300.063 588.567C294.232 593.158 287.379 595.481 279.689 595.481L279.682 595.467Z" fill="#FE86F6"/>
<path d="M351.729 597V560.15H396.758V597H351.729ZM355.618 558.301L355.399 553.717C354.898 543.195 353.629 530.687 351.626 516.53L351.585 516.248L351.427 509.273C350.816 493.59 350.508 480.312 350.508 469.811V465H398.13V469.811C398.13 476.628 397.869 489.899 397.361 509.252V509.444C397.107 513.08 397.059 514.936 397.059 515.856V516.193L397.011 516.53C395.008 530.687 393.739 543.195 393.238 553.71L393.019 558.294H355.612L355.618 558.301Z" fill="#FE86F6"/>
<path d="M469.412 595.468C457.833 595.468 445.883 594.169 433.905 591.612C421.838 589.035 410.808 585.214 401.114 580.252L396.312 577.792L399.303 573.297C402.658 568.259 405.464 563.531 407.652 559.243C409.84 554.954 411.994 549.889 414.052 544.192L415.733 539.539C406.143 534.33 401.087 525.973 401.087 515.238C401.087 499.686 407.652 488.037 420.611 480.621C432.698 473.708 448.448 470.196 467.43 470.196C478.337 470.196 489.69 471.605 501.16 474.388C512.63 477.171 523.146 481.041 532.414 485.893L536.393 487.975L534.589 492.092C531.714 498.634 528.641 506.764 525.451 516.242C524.806 518.386 524.271 519.994 523.812 521.155L522.33 524.88C531.927 530.674 536.983 539.457 536.983 550.425C536.983 566.438 530.96 578.197 519.085 585.372C507.835 592.169 491.59 595.468 469.426 595.468H469.412ZM422.195 542.364C428.726 545.972 436.354 548.858 444.902 550.954C454.046 553.195 462.648 554.336 470.482 554.336C480.148 554.336 484.511 553.339 486.466 552.501C487.399 552.102 488.016 551.759 488.414 551.504C487.145 550.144 481.726 547.587 465.138 547.587C446.322 547.587 432.197 545.876 422.195 542.364ZM448.496 511.981C448.97 512.324 449.944 512.881 451.858 513.493C455.994 514.812 462.257 515.486 470.482 515.486C488.38 515.486 502.21 517.121 512.424 520.461C507.945 517.836 501.97 515.52 494.588 513.547C484.552 510.867 475.621 509.506 468.04 509.506C456.186 509.506 451.775 510.51 450.198 511.108C449.278 511.458 448.771 511.774 448.503 511.987L448.496 511.981Z" fill="#FE86F6"/>
<path d="M590.224 595.467C572.004 595.467 558.188 590.993 549.153 582.169C540.084 573.31 535.488 561.607 535.488 547.367C535.488 533.127 540.084 521.416 549.153 512.564C558.188 503.74 572.004 499.266 590.217 499.266C603.69 499.266 614.92 501.678 623.598 506.434C632.516 511.327 638.957 518.289 642.73 527.113L645.028 532.495L639.314 533.718C630.485 535.608 620.456 538.487 609.494 542.281L606.448 543.333L604.321 540.913C602.517 538.865 600.562 537.319 598.511 536.329C596.535 535.374 594.203 534.893 591.582 534.893C586.835 534.893 583.343 536.006 581.189 538.199C578.967 540.46 577.794 543.463 577.608 547.367C577.794 551.277 578.967 554.274 581.189 556.535C583.343 558.727 586.842 559.84 591.589 559.84C594.244 559.84 596.508 559.373 598.518 558.404C600.569 557.414 602.524 555.875 604.328 553.827L606.544 551.305L609.672 552.521C618.041 555.779 628.009 558.638 639.307 561.015L645.035 562.225L642.73 567.62C638.957 576.451 632.516 583.406 623.598 588.299C614.933 593.055 603.704 595.467 590.217 595.467H590.224Z" fill="#FE86F6"/>
<path d="M701.452 593.942L701.658 588.932C702.261 574.073 702.57 560.191 702.57 547.669C702.57 544.357 701.836 541.78 700.389 540.027C699.113 538.488 697.686 537.794 695.772 537.794C694.427 537.794 692.623 538.158 690.579 540.996C688.699 543.608 687.746 547.449 687.746 552.411L687.595 552.446C687.595 568.754 687.801 581.008 688.199 588.877L688.452 593.928H641.832L642.113 588.849C642.916 574.307 643.327 554.329 643.327 529.457C643.327 504.586 642.916 484.607 642.113 470.065L641.832 464.986H688.034L688.171 507.905C689.186 506.936 690.263 506.056 691.395 505.252C697.034 501.266 703.489 499.246 710.576 499.246C717.662 499.246 723.712 500.84 728.933 503.988C734.188 507.156 738.214 511.499 740.904 516.894C743.517 522.138 744.848 528.055 744.848 534.495V556.521C744.848 569.95 745.102 580.808 745.603 588.801L745.925 593.914H701.452V593.942Z" fill="#FE86F6"/>
<path d="M782.143 595.467C775.18 595.467 769.006 593.873 763.786 590.725C758.538 587.564 754.511 583.221 751.815 577.819C749.202 572.575 747.871 566.658 747.871 560.218V538.192C747.871 524.804 747.617 513.939 747.116 505.912L746.801 500.799H791.267L791.061 505.809C790.458 520.667 790.149 535.574 790.149 550.13C790.149 553.449 790.883 556.019 792.33 557.772C793.606 559.318 795.033 560.005 796.954 560.005C798.038 560.005 799.698 559.744 801.626 557.483C803.327 555.49 804.404 552.569 804.829 548.796V545.415C805.035 537.505 805.131 531.986 805.131 529.017C805.131 522.124 804.925 514.324 804.527 505.843L804.287 500.806H850.894L850.612 505.884C849.906 518.578 849.549 532.488 849.549 547.229C849.549 561.971 849.906 576.087 850.612 588.877L850.908 594.196L845.598 593.949C839.184 593.646 834.073 593.495 830.403 593.495C826.932 593.495 821.814 593.646 815.201 593.949L811.072 594.134L808.684 582.025C806.873 584.575 804.822 586.76 802.524 588.567C796.693 593.158 789.84 595.481 782.15 595.481L782.143 595.467Z" fill="#FE86F6"/>
<path d="M853.551 593.942L853.75 571.805C853.949 552.872 854.052 538.116 854.052 527.945C854.052 517.774 853.949 504.297 853.75 486.394L853.551 465.007H899.307L899.238 469.886C899.032 484.181 898.936 496.098 898.936 505.307C898.936 515.458 898.984 523.038 899.087 527.849V528.028C898.984 534.048 898.936 543.491 898.936 556.095C898.936 571.214 899.039 582.286 899.238 588.994L899.389 593.949H853.558L853.551 593.942Z" fill="#FE86F6"/>
<path d="M954.867 595.467C936.352 595.467 922.309 590.973 913.131 582.114C903.897 573.207 899.219 561.414 899.219 547.064C899.219 532.715 903.877 521.402 913.055 512.544C922.186 503.733 936.256 499.266 954.867 499.266C965.884 499.266 975.646 501.163 983.878 504.895C992.315 508.73 998.901 514.173 1003.46 521.079C1008.05 528.028 1010.36 536.048 1010.36 544.92C1010.36 547.841 1010.25 550.026 1010.01 551.614L1009.38 555.696H987.431C992.727 557.923 998.963 560.266 1006.06 562.699L1011.02 564.397L1008.9 569.194C1005.29 577.338 998.771 583.867 989.503 588.588C980.564 593.144 968.916 595.46 954.88 595.46L954.867 595.467ZM943.5 555.696C944.124 556.61 944.865 557.442 945.729 558.198C948.021 560.205 951.553 561.215 956.239 561.215C959.813 561.215 963.215 560.507 966.343 559.105C968.388 558.191 970.11 557.064 971.55 555.689H943.5V555.696ZM963.469 533.223C961.74 532.398 959.573 531.986 957.007 531.986C953.886 531.986 951.217 532.405 949.022 533.223H963.469Z" fill="#FE86F6"/>
<path d="M129.296 556.088C129.296 563.022 127.718 568.912 124.562 573.757C121.407 578.602 115.754 582.375 107.611 585.076C99.4685 587.777 88.1633 589.131 73.7162 589.131H5C5.81633 569.345 6.22107 550.837 6.22107 533.601C6.22107 516.365 5.81633 497.857 5 478.071H40.8844V478.222H73.4075C86.0298 478.222 95.9768 479.267 103.262 481.356C110.54 483.446 115.679 486.483 118.683 490.456C121.688 494.435 123.19 499.479 123.19 505.603C123.19 511.726 121.029 517.354 116.701 522.2C112.372 527.045 104.963 530.948 94.4813 533.903C117.689 533.903 129.296 541.298 129.296 556.081V556.088ZM40.8844 506.063V520.289H70.2039C76.5151 520.289 80.9191 519.732 83.4093 518.605C85.8995 517.485 87.148 515.444 87.148 512.489C87.148 510.145 85.8515 508.489 83.2515 507.52C80.6516 506.551 76.3024 506.07 70.197 506.07H40.8844V506.063ZM70.2039 561.132C75.4929 561.132 79.5403 560.905 82.346 560.445C85.1449 559.985 87.1549 559.174 88.3759 557.998C89.597 556.83 90.2075 555.222 90.2075 553.181C90.2075 550.528 88.7532 548.617 85.8583 547.442C82.9566 546.274 77.7361 545.683 70.2108 545.683H40.8844V561.132H70.2039Z" fill="white" stroke="#D150AF" stroke-width="2" stroke-miterlimit="10"/>
<path d="M236.655 563.737C236.655 567.207 237.059 569.654 237.876 571.077C238.692 572.506 240.318 573.523 242.76 574.135L242.15 588.512C238.891 589.021 236.065 589.378 233.677 589.584C231.283 589.783 228.107 589.893 224.135 589.893C215.382 589.893 209.578 587.928 206.732 584.004C203.878 580.079 202.458 575.056 202.458 568.939V565.118C199.501 573.482 195.207 579.701 189.554 583.784C183.902 587.866 176.699 589.9 167.945 589.9C157.765 589.9 150.103 587.859 144.965 583.784C139.82 579.708 137.254 573.64 137.254 565.579C137.254 558.953 139.442 553.751 143.819 549.978C148.196 546.205 154.966 543.607 164.124 542.178C156.386 536.673 148.648 531.876 140.917 527.8C147.022 520.048 153.999 514.165 161.833 510.131C169.667 506.104 179.189 504.09 190.384 504.09C206.059 504.09 217.687 507.485 225.274 514.262C232.854 521.045 236.648 531.47 236.648 545.545V563.75L236.655 563.737ZM179.854 533.601C175.882 535.333 172.322 537.937 169.166 541.401C175.272 540.686 182.907 540.329 192.072 540.329C195.838 540.329 198.486 539.869 200.009 538.954C201.532 538.04 202.3 536.865 202.3 535.436C202.3 534.212 201.539 533.168 200.009 532.302C198.479 531.436 196.346 531.003 193.595 531.003C188.402 531.003 183.819 531.869 179.854 533.601ZM192.984 561.668C197.567 559.476 200.722 556.596 202.451 553.022V549.043C200.009 550.267 197.306 551.208 194.356 551.875C191.4 552.541 187.839 553.174 183.668 553.785L178.174 554.706C172.473 555.826 169.626 557.765 169.626 560.52C169.626 563.482 172.274 564.96 177.57 564.96C183.271 564.96 188.409 563.867 192.991 561.668H192.984Z" fill="white" stroke="#D150AF" stroke-width="2" stroke-miterlimit="10"/>
<path d="M342.285 547.215C342.285 562.204 342.642 576.183 343.355 589.131C336.838 588.822 331.7 588.67 327.934 588.67C324.367 588.67 319.229 588.822 312.513 589.131L308.081 566.644C305.735 574.802 302.072 580.849 297.085 584.774C292.098 588.698 286.294 590.663 279.681 590.663C273.576 590.663 268.28 589.309 263.8 586.609C259.321 583.908 255.939 580.258 253.648 575.674C251.356 571.084 250.211 565.936 250.211 560.225V538.199C250.211 524.639 249.957 513.774 249.449 505.616H283.804C283.193 520.605 282.885 535.443 282.885 550.129C282.885 554.617 283.975 558.184 286.171 560.837C288.359 563.49 291.13 564.816 294.492 564.816C297.647 564.816 300.419 563.414 302.813 560.61C305.2 557.806 306.654 553.957 307.162 549.057V545.539C307.361 537.587 307.464 532.075 307.464 529.017C307.464 521.98 307.258 514.179 306.853 505.609H343.348C342.635 518.461 342.278 532.33 342.278 547.215H342.285Z" fill="white" stroke="#D150AF" stroke-width="2" stroke-miterlimit="10"/>
<path d="M393.327 469.811C393.327 476.642 393.074 489.748 392.566 509.121C392.36 512.179 392.257 514.427 392.257 515.849C390.22 530.227 388.951 542.769 388.443 553.483H360.194C359.686 542.776 358.41 530.234 356.38 515.849L356.229 509.121C355.618 493.418 355.316 480.312 355.316 469.811H393.334H393.327ZM391.955 592.189H356.531V564.96H391.955V592.189Z" fill="white" stroke="#D150AF" stroke-width="2" stroke-miterlimit="10"/>
<path d="M411.926 561.442C414.217 556.954 416.433 551.751 418.566 545.841C425.79 550.026 434.193 553.284 443.763 555.634C453.332 557.985 462.237 559.153 470.482 559.153C478.728 559.153 484.888 558.418 488.346 556.933C491.803 555.456 493.539 553.848 493.539 552.116C493.539 545.896 484.072 542.783 465.138 542.783C444.373 542.783 429.309 540.618 419.938 536.281C410.574 531.945 405.889 524.935 405.889 515.245C405.889 501.479 411.59 491.328 422.991 484.806C434.392 478.284 449.203 475.013 467.423 475.013C478.008 475.013 488.874 476.367 500.021 479.068C511.169 481.769 521.219 485.466 530.178 490.16C527.221 496.888 524.12 505.101 520.862 514.791C520.251 516.832 519.744 518.365 519.332 519.382C514.139 515.204 506.298 511.712 495.816 508.901C485.327 506.097 476.066 504.695 468.026 504.695C458.354 504.695 451.844 505.335 448.482 506.606C445.121 507.884 443.44 509.74 443.44 512.187C443.44 514.633 445.752 516.599 450.39 518.076C455.02 519.554 461.715 520.296 470.469 520.296C492.05 520.296 507.725 522.77 517.5 527.718C527.276 532.667 532.16 540.24 532.16 550.432C532.16 564.713 526.967 574.98 516.588 581.255C506.202 587.53 490.479 590.664 469.405 590.664C458.107 590.664 446.603 589.413 434.9 586.918C423.19 584.423 412.66 580.774 403.289 575.984C406.746 570.781 409.628 565.943 411.919 561.449L411.926 561.442Z" fill="white" stroke="#D150AF" stroke-width="2" stroke-miterlimit="10"/>
<path d="M577.781 559.909C580.881 563.07 585.491 564.651 591.597 564.651C594.958 564.651 597.956 564.019 600.604 562.74C603.252 561.469 605.694 559.551 607.93 557.002C616.581 560.369 626.713 563.276 638.313 565.723C634.952 573.578 629.278 579.695 621.287 584.079C613.295 588.464 602.936 590.656 590.211 590.656C573.212 590.656 560.638 586.677 552.495 578.726C544.352 570.774 540.277 560.321 540.277 547.367C540.277 534.412 544.345 523.959 552.495 516.007C560.638 508.056 573.212 504.077 590.211 504.077C602.936 504.077 613.295 506.269 621.287 510.654C629.278 515.038 634.952 521.162 638.313 529.01C629.251 530.948 619.126 533.855 607.93 537.731C605.687 535.182 603.245 533.271 600.604 531.993C597.956 530.721 594.951 530.082 591.597 530.082C585.491 530.082 580.881 531.663 577.781 534.824C574.673 537.986 573.02 542.164 572.821 547.367C573.027 552.569 574.68 556.747 577.781 559.909Z" fill="white" stroke="#D150AF" stroke-width="2" stroke-miterlimit="10"/>
<path d="M706.45 589.131C707.06 574.142 707.362 560.321 707.362 547.676C707.362 543.188 706.264 539.621 704.076 536.969C701.888 534.316 699.116 532.989 695.755 532.989C692.188 532.989 689.163 534.721 686.672 538.192C684.175 541.662 682.934 546.404 682.934 552.418L682.783 552.109C682.783 568.733 682.989 581.076 683.393 589.131H646.898C647.715 574.444 648.119 554.562 648.119 529.471C648.119 504.379 647.715 484.497 646.898 469.811H683.242L683.393 516.928L683.242 525.038C685.685 517.897 689.327 512.626 694.164 509.204C699 505.788 704.467 504.077 710.579 504.077C716.692 504.077 721.981 505.431 726.46 508.132C730.94 510.832 734.322 514.482 736.613 519.073C738.904 523.663 740.05 528.811 740.05 534.522V556.548C740.05 570.115 740.304 580.973 740.811 589.131H706.457H706.45Z" fill="white" stroke="#D150AF" stroke-width="2" stroke-miterlimit="10"/>
<path d="M844.75 547.215C844.75 562.204 845.107 576.183 845.82 589.131C839.303 588.822 834.165 588.67 830.399 588.67C826.832 588.67 821.694 588.822 814.978 589.131L810.546 566.644C808.2 574.802 804.537 580.849 799.55 584.774C794.562 588.698 788.759 590.663 782.146 590.663C776.041 590.663 770.745 589.309 766.265 586.609C761.786 583.908 758.404 580.258 756.112 575.674C753.821 571.084 752.676 565.936 752.676 560.225V538.199C752.676 524.639 752.422 513.774 751.914 505.616H786.269C785.658 520.605 785.349 535.443 785.349 550.129C785.349 554.617 786.44 558.184 788.635 560.837C790.824 563.49 793.595 564.816 796.957 564.816C800.112 564.816 802.884 563.414 805.278 560.61C807.665 557.806 809.119 553.957 809.627 549.057V545.539C809.826 537.587 809.929 532.075 809.929 529.017C809.929 521.98 809.723 514.179 809.318 505.609H845.813C845.1 518.461 844.743 532.33 844.743 547.215H844.75Z" fill="white" stroke="#D150AF" stroke-width="2" stroke-miterlimit="10"/>
<path d="M894.125 556.088C894.125 571.283 894.228 582.299 894.434 589.131H858.398L858.549 571.846C858.755 552.878 858.858 538.247 858.858 527.945C858.858 517.643 858.755 504.29 858.549 486.339L858.398 469.817H894.434C894.228 484.298 894.125 496.125 894.125 505.307C894.125 515.506 894.173 523.052 894.276 527.945C894.173 534.061 894.125 543.449 894.125 556.095V556.088Z" fill="white" stroke="#D150AF" stroke-width="2" stroke-miterlimit="10"/>
<path d="M986.317 550.885H936.542C937.358 555.373 939.368 559.022 942.572 561.826C945.775 564.63 950.33 566.032 956.236 566.032C960.51 566.032 964.53 565.194 968.296 563.51C972.062 561.826 975.067 559.455 977.303 556.397C984.122 559.764 993.184 563.386 1004.48 567.255C1001.33 574.396 995.599 580.079 987.305 584.313C979.005 588.546 968.193 590.663 954.858 590.663C937.55 590.663 924.749 586.663 916.456 578.657C908.155 570.65 904.012 560.122 904.012 547.071C904.012 534.02 908.134 523.972 916.38 516.021C924.626 508.07 937.454 504.09 954.858 504.09C965.244 504.09 974.251 505.822 981.886 509.293C989.521 512.763 995.373 517.581 999.447 523.746C1003.52 529.917 1005.55 536.975 1005.55 544.934C1005.55 547.586 1005.45 549.572 1005.25 550.899H986.317V550.885ZM944.479 530.082C941.22 532.02 938.929 534.673 937.605 538.034H973.64C972.419 534.769 970.437 532.144 967.686 530.158C964.935 528.172 961.375 527.175 956.998 527.175C951.908 527.175 947.737 528.144 944.479 530.082Z" fill="white" stroke="#D150AF" stroke-width="2" stroke-miterlimit="10"/>
</g>
<path d="M755.837 520.674C796.616 520.674 829.674 487.616 829.674 446.837C829.674 406.058 796.616 373 755.837 373C715.058 373 682 406.058 682 446.837C682 487.616 715.058 520.674 755.837 520.674Z" fill="#FEF501"/>
<path d="M734.922 453.867C737.731 452.929 737.499 444.657 734.404 435.391C731.309 426.125 726.523 419.374 723.715 420.312C720.906 421.25 721.138 429.522 724.233 438.788C727.328 448.054 732.114 454.805 734.922 453.867Z" fill="black"/>
<path d="M773.051 441.127C775.86 440.189 775.628 431.917 772.533 422.651C769.438 413.385 764.652 406.634 761.843 407.572C759.035 408.51 759.267 416.782 762.362 426.048C765.457 435.314 770.242 442.065 773.051 441.127Z" fill="black"/>
<path d="M787.519 408.453C795.299 414.416 801.45 422.696 804.782 432.693C813.689 459.364 799.295 488.215 772.624 497.122C745.953 506.029 717.103 491.635 708.196 464.964C705.352 456.433 704.876 447.689 706.442 439.446" stroke="black" stroke-miterlimit="10"/>
<defs>
<clipPath id="clip0_667_1366">
<rect width="679.617" height="86" fill="white" transform="translate(166.367 626)"/>
</clipPath>
<clipPath id="clip1_667_1366">
<rect width="1011" height="132" fill="white" transform="translate(0 465)"/>
</clipPath>
</defs>
</svg>
</div>
</div>

</div>

<style>
  .container {
    width: 100vw;
    height: 100vh;
    background: #3d68ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logos {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .content {
    position: relative;
    z-index: 0;
  }
  .SVGbg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .text {
    z-index: 2;

    font-family: Goethe Bold, sans-serif;
    color: white;
    text-align: center;
  }
</style>
