<script>
  import Button from "./Button.svelte";
  import MenuButton from "./MenuButton.svelte";
  import SchoolTypes from "./SchoolTypes.svelte";
  import StartScreen from "./StartScreen.svelte";
  import PlayerAvatarsScreen from "./PlayerAvatarsScreen.svelte";
  import HowToPlayScreen from "./HowToPlayScreen.svelte";
  import MapScreen from "./MapScreen.svelte";
  import ChapterScreen from "./ChapterScreen.svelte";
  import DialogScreen from "./DialogScreen.svelte";
  import DialogChapterTwoScreen from "./DialogChapterTwoScreen.svelte";
  import DialogChapterThreeScreen from "./DialogChapterThreeScreen.svelte";
  import DialogChapterFourScreen from "./DialogChapterFourScreen.svelte";
  import DialogChapterFiveScreen from "./DialogChapterFiveScreen.svelte";
  import DialogChapterSixScreen from "./DialogChapterSixScreen.svelte";
  import SceneChapterOneScreen from "./SceneChapterOne.svelte";
  import SceneChapterTwoScreen from "./SceneChapterTwo.svelte";
  import SceneChapterThreeScreen from "./SceneChapterThree.svelte";
  import SceneChapterFourScreen from "./SceneChapterFour.svelte";
  import SceneChapterFiveScreen from "./SceneChapterFive.svelte";
  import SceneChapterSixScreen from "./SceneChapterSix.svelte";
  import ChapterResultScreen from "./ChapterResultScreen.svelte";
  import RobertScreen from "./RobertScreen.svelte";
  import EmailScreen from "./EmailScreen.svelte";
  import FinalDialogChapterOneScreen from "./FinalDialogChapterOneScreen.svelte";
  import FinalDialogChapterTwoScreen from "./FinalDialogChapterTwoScreen.svelte";
  import FinalDialogChapterThreeScreen from "./FinalDialogChapterThreeScreen.svelte";
  import FinalDialogChapterFourScreen from "./FinalDialogChapterFourScreen.svelte";
  import FinalDialogChapterFiveScreen from "./FinalDialogChapterFiveScreen.svelte";
  import FinalDialogChapterSixScreen from "./FinalDialogChapterSixScreen.svelte";
  import FinalScreen from "./FinalScreen.svelte";
  import MobileScreen from "./MobileScreen.svelte";
  import AboutScreen from "./AboutScreen.svelte";
  import LoadingScreen from "./LoadingScreen.svelte";

  import SceneChapterOneRedUpdScreen from "./SceneChapterOneRedUpd.svelte";
  import SceneChapterTwoRedUpdScreen from "./SceneChapterTwoRedUpd.svelte";
  import SceneChapterThreeRedUpdScreen from "./SceneChapterThreeRedUpd.svelte";
  import SceneChapterFourRedUpdScreen from "./SceneChapterFourRedUpd.svelte";
  import SceneChapterFiveRedUpdScreen from "./SceneChapterFiveRedUpd.svelte";
  import SceneChapterSixRedUpdScreen from "./SceneChapterSixRedUpd.svelte";

  import {
    aboutScreenState,
    startScreenState,
    playerAvatarsScreenState,
    howToPlayScreenState,
    mapScreenState,
    chapterScreenState,
    chapterOneState,
    chapterTwoState,
    chapterThreeState,
    chapterFourState,
    chapterFiveState,
    chapterSixState,
    dialogScreenState,
    dialogChapterTwoScreenState,
    dialogChapterThreeScreenState,
    dialogChapterFourScreenState,
    dialogChapterFiveScreenState,
    dialogChapterSixScreenState,
    sceneChapterOneState,
    sceneChapterTwoState,
    sceneChapterThreeState,
    sceneChapterFourState,
    sceneChapterFiveState,
    sceneChapterSixState,
    chapterResultScreenState,
    robertScreenState,
    emailScreenState,
    width,
    height,
    finalDialogChapterOneScreenState,
    finalDialogChapterTwoScreenState,
    finalDialogChapterThreeScreenState,
    finalDialogChapterFourScreenState,
    finalDialogChapterFiveScreenState,
    finalDialogChapterSixScreenState,
    schoolTypesScreenState,
    finalScreenState,
    scrollWidth
  } from "./stores";
</script>

<style>
  @font-face {
    font-family: "Goethe";
    src: local("Goethe"),
    url("./fonts/GoetheFFClan-Book.ttf")
      /* url("https://chnwoggqycavwhnapass.supabase.co/storage/v1/object/sign/fonts/GoetheFFClan-Book.ttf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJmb250cy9Hb2V0aGVGRkNsYW4tQm9vay50dGYiLCJpYXQiOjE2NTA4OTQ2MzUsImV4cCI6MTk2NjI1NDYzNX0.zOKCtAadLi0mPqyr1JW-nY56KG6oavUKJa3sbaP3VFI") */
        format("truetype");
  }
  @font-face {
    font-family: "Goethe Bold";
    src: local("Goethe Bold"),
    url("./fonts/GoetheFFClan-Bold.ttf")
      /* url("https://chnwoggqycavwhnapass.supabase.co/storage/v1/object/sign/fonts/GoetheFFClan-Bold.ttf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJmb250cy9Hb2V0aGVGRkNsYW4tQm9sZC50dGYiLCJpYXQiOjE2NTMzMDY2MDYsImV4cCI6MTk2ODY2NjYwNn0.keLRM13nB3aNm865Gw6_B4yfJmEE31bLVtluJb8OQhc") */
        format("truetype");
  }
  @font-face {
    font-family: "Montserrat";
    font-weight: 900;
    src: local("Montserrat"),
    url("./fonts/Montserrat-ExtraBold.ttf")
      /* url("https://chnwoggqycavwhnapass.supabase.co/storage/v1/object/sign/fonts/montserrat.ttf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJmb250cy9tb250c2VycmF0LnR0ZiIsImlhdCI6MTY2MDIyODgxMCwiZXhwIjoxOTc1NTg4ODEwfQ.2lsj0dXHKBunfAo8_7RxmYD6VKK74oH1xCSYBvp65CI") */
        format("truetype");
  }
  :global(body) {
    margin: 0;
    overflow: hidden;
    background: #3d68ff;
  }
  main {
    /* font-family: sans-serif; */
    /* font-family: Dela Gothic One; */
    text-align: center;
  }

  /* :global(:root) {
                                          --scrollWidth: $scrollWidth;
                                        } */

  /* width */
  :global(::-webkit-scrollbar) {
    width: 5px;
    height: 5px;

    /* height: 0.5em; */
  }

  /* Track */
  :global(::-webkit-scrollbar-track) {
    background: rgba(61, 104, 255, 0.1);
    border-radius: 5px;
  }

  /* Handle */
  :global(::-webkit-scrollbar-thumb) {
    background: rgba(61, 104, 255, 0.2);
    border-radius: 5px;
  }

  /* Handle on hover */
  :global(::-webkit-scrollbar-thumb:hover) {
    background: rgba(61, 104, 255, 1);
  }
</style>




{#if $width < 1000}
<MobileScreen />
{:else}
<div style="z-index: 100;">
{#if $aboutScreenState}
  <AboutScreen />
  {/if}
{#if $schoolTypesScreenState}
  <SchoolTypes />
  {/if}
</div>

<MenuButton />

<main style="z-index: 0; position: relative; height: 100vh;">
  
  
  {#if $startScreenState}
  <!-- <LoadingScreen /> -->
  <StartScreen />
  {/if}
  
  {#if $playerAvatarsScreenState}
  <PlayerAvatarsScreen />
  {/if}
  {#if $howToPlayScreenState}
  <HowToPlayScreen />
  {/if}
  {#if $mapScreenState}
  <MapScreen />
  {/if}
  {#if $chapterScreenState}
  <ChapterScreen />
  {/if}
  {#if $dialogScreenState}
  <DialogScreen />
  {/if}
  {#if $dialogChapterTwoScreenState}
  <DialogChapterTwoScreen />
  {/if}
  {#if $dialogChapterThreeScreenState}
  <DialogChapterThreeScreen />
  {/if}
  {#if $dialogChapterFourScreenState}
  <DialogChapterFourScreen />
  {/if}
  {#if $dialogChapterFiveScreenState}
  <DialogChapterFiveScreen />
  {/if}
  {#if $dialogChapterSixScreenState}
  <DialogChapterSixScreen />
  {/if}
  {#if $sceneChapterOneState}
  <!-- <SceneChapterOneScreen /> -->
  <SceneChapterOneRedUpdScreen />
  {/if}
  {#if $sceneChapterTwoState}
  <!-- <SceneChapterTwoScreen /> -->
  <SceneChapterTwoRedUpdScreen />
  {/if}
  {#if $sceneChapterThreeState}
  <!-- <SceneChapterThreeScreen /> -->
  <SceneChapterThreeRedUpdScreen />
  {/if}
  {#if $sceneChapterFourState}
  <!-- <SceneChapterFourScreen /> -->
  <SceneChapterFourRedUpdScreen />
  {/if}
  {#if $sceneChapterFiveState}
  <!-- <SceneChapterFiveScreen /> -->
  <SceneChapterFiveRedUpdScreen />
  {/if}
  {#if $sceneChapterSixState}
  <!-- <SceneChapterSixScreen /> -->
  <SceneChapterSixRedUpdScreen />
  {/if}
  {#if $chapterResultScreenState}
  <ChapterResultScreen />
  {/if}
  {#if $robertScreenState}
  <RobertScreen />
  {/if}
  {#if $emailScreenState}
  <EmailScreen />
  {/if}
  {#if $finalDialogChapterOneScreenState}
  <FinalDialogChapterOneScreen />
  {/if}
  {#if $finalDialogChapterTwoScreenState}
  <FinalDialogChapterTwoScreen />
  {/if}
  {#if $finalDialogChapterThreeScreenState}
  <FinalDialogChapterThreeScreen />
  {/if}
  {#if $finalDialogChapterFourScreenState}
  <FinalDialogChapterFourScreen />
  {/if}
  {#if $finalDialogChapterFiveScreenState}
  <FinalDialogChapterFiveScreen />
  {/if}
  {#if $finalDialogChapterSixScreenState}
  <FinalDialogChapterSixScreen />
  {/if}

  {#if $finalScreenState}
  <FinalScreen />
  {/if}


  
</main>
{/if}