<script>
  import MenuPanel from "./MenuPanel.svelte";
  import { fade } from "svelte/transition";
  import howler from "howler";
  import {
    playSoundState,
    sceneChapterOneState,
    sceneChapterTwoState,
    sceneChapterThreeState,
    sceneChapterFourState,
    sceneChapterFiveState,
    sceneChapterSixState,
    mapScreenState,
    aboutScreenState,
    startScreenState,
    playerAvatarsScreenState,
    howToPlayScreenState,
    dialogScreenState,
    dialogChapterTwoScreenState,
    dialogChapterThreeScreenState,
    dialogChapterFourScreenState,
    dialogChapterFiveScreenState,
    dialogChapterSixScreenState,
    chapterResultScreenState,
    robertScreenState,
    finalDialogChapterOneScreenState,
    finalDialogChapterTwoScreenState,
    finalDialogChapterThreeScreenState,
    finalDialogChapterFourScreenState,
    finalDialogChapterFiveScreenState,
    finalDialogChapterSixScreenState,
    schoolTypesScreenState,
    finalScreenState,
    menuState
  } from "./stores";

  // let menuState = false;
  let menuButtonRotation = 0;

  let clickSound = new Howl({
    src: [
      "https://chnwoggqycavwhnapass.supabase.co/storage/v1/object/sign/music/Clicks_01.mp3?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJtdXNpYy9DbGlja3NfMDEubXAzIiwiaWF0IjoxNjQ5Njg3NDE5LCJleHAiOjE5NjUwNDc0MTl9.NbHKznEcpApqfYVOWEx8RBZzHwdZ8jWcgN0nTWXeTBs"
    ],
    preload: true,
    volume: 0.8,
    loop: false
  });

  // let schoolSound = new Howl({
  //   src: [
  //     "https://chnwoggqycavwhnapass.supabase.co/storage/v1/object/sign/music/SchoolInSchool-1.mp3?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJtdXNpYy9TY2hvb2xJblNjaG9vbC0xLm1wMyIsImlhdCI6MTY0OTY3MDg4MCwiZXhwIjoxOTY1MDMwODgwfQ.oVofi-0DXTALouq-qaQbeBJiOWtiLADM0GmNR3QpZPk"
  //   ],
  //   preload: true,
  //   volume: 0.1,
  //   loop: true
  // });

  let calmThemeSound = new Howl({
    src: [
      "https://chnwoggqycavwhnapass.supabase.co/storage/v1/object/sign/music/CalmTheme.mp3?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJtdXNpYy9DYWxtVGhlbWUubXAzIiwiaWF0IjoxNjQ5NTAzMTE2LCJleHAiOjE5NjQ4NjMxMTZ9.qXYR4H0ZjONVE7e7_BvsF6rHkP47ZVzJqSod_ooC4_c"
    ],
    preload: true,
    volume: 0.25,
    loop: true
  });

  let themeSound2 = new Howl({
    src: [
      "https://chnwoggqycavwhnapass.supabase.co/storage/v1/object/sign/music/IntroThemeCalm_V2.mp3?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJtdXNpYy9JbnRyb1RoZW1lQ2FsbV9WMi5tcDMiLCJpYXQiOjE2NjEzMjY3ODIsImV4cCI6MTk3NjY4Njc4Mn0.lDriVplvx7Fcu08VBAy3sq1_-EasDQO1zHgjBHpPSGo"
    ],
    preload: true,
    volume: 0.25,
    loop: true
  });

  menuState.subscribe(value => {
    menuButtonRotation += 45;
    if (menuButtonRotation > 45) {
      menuButtonRotation = 0;
    }
  });

  function changeMenuState() {
    if (playSoundStateValue && !clickSound.playing()) {
      clickSound.play();
    }
    if ($menuState == false) {
      menuState.set(true);
    } else {
      menuState.set(false);
    }
    // menuState = !menuState;
  }

  let playSoundStateValue;
  // let sceneChapterOneStateValue;
  // sceneChapterOneState.subscribe(value => {
  //   sceneChapterOneStateValue = value;
  //   console.log(sceneChapterOneStateValue);
  //   if (sceneChapterOneStateValue) {
  //     if (playSoundStateValue) {
  //       schoolSound.play();
  //     }
  //   } else {
  //     schoolSound.pause();
  //   }
  // });

  playSoundState.subscribe(value => {
    playSoundStateValue = value;

    if (playSoundStateValue) {
      if (
        $aboutScreenState ||
        $mapScreenState ||
        $startScreenState ||
        $playerAvatarsScreenState ||
        $howToPlayScreenState ||
        $dialogScreenState ||
        $dialogChapterTwoScreenState ||
        $dialogChapterThreeScreenState ||
        $dialogChapterFourScreenState ||
        $dialogChapterFiveScreenState ||
        $dialogChapterSixScreenState ||
        $finalDialogChapterOneScreenState ||
        $finalDialogChapterTwoScreenState ||
        $finalDialogChapterThreeScreenState ||
        $finalDialogChapterFourScreenState ||
        $finalDialogChapterFiveScreenState ||
        $finalDialogChapterSixScreenState ||
        $schoolTypesScreenState ||
        $finalScreenState
      ) {
        if (!calmThemeSound.playing()) {
          calmThemeSound.play();
          themeSound2.pause();
        }
      }
      if ($chapterResultScreenState || $robertScreenState) {
        if (!themeSound2.playing()) {
          calmThemeSound.pause();
          themeSound2.play();
        }
      }
    } else {
      calmThemeSound.pause();
      themeSound2.pause();
    }
  });
</script>

<div class="container" on:click={changeMenuState}>
<svg id="menuButton" style="transform: rotate({menuButtonRotation}deg); transition: transform 1s;" width="109" height="109" viewBox="0 0 109 109" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M54.4799 107.69C83.8283 107.69 107.62 83.8985 107.62 54.55C107.62 25.2016 83.8283 1.41003 54.4799 1.41003C25.1314 1.41003 1.33984 25.2016 1.33984 54.55C1.33984 83.8985 25.1314 107.69 54.4799 107.69Z" fill="#A5C500" stroke="black" stroke-miterlimit="10"/>
<path d="M89.6803 54.5501L70.9003 46.9401C66.8903 45.3101 63.7002 42.1301 62.0802 38.1201L54.4802 19.3401L46.8802 38.1201C45.2502 42.1301 42.0703 45.3201 38.0603 46.9401L19.2803 54.5401L38.0603 62.1401C42.0703 63.7701 45.2602 66.9501 46.8802 70.9601L54.4902 89.7401L62.0902 70.9601C63.7202 66.9501 66.9003 63.7601 70.9103 62.1401L89.6902 54.5401L89.6803 54.5501Z" fill="white" stroke="black" stroke-miterlimit="10"/>
</svg>
</div>

{#if $menuState}
<MenuPanel />
<!-- <div transition:fade>

</div> -->
{/if}

<style>
  .container {
    position: absolute;
    top: 20px;
    right: 25px;
    z-index: 5;
    cursor: pointer;
  }
</style>