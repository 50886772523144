<script>
</script>



<div id="scoreContainer">
<div id="textContainer">
<svg width="63" height="60" viewBox="0 0 63 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.6301 49.88L16.7701 58.46C16.2066 58.8286 15.5442 59.0165 14.8711 58.9988C14.1981 58.9811 13.5464 58.7585 13.0031 58.3608C12.4598 57.9632 12.0507 57.4092 11.8304 56.773C11.6101 56.1368 11.5891 55.4485 11.7701 54.8L15.9601 39.92C16.1365 39.2892 16.1224 38.6203 15.9196 37.9976C15.7167 37.3748 15.3342 36.8259 14.8201 36.42L2.6801 26.84C2.1485 26.419 1.75858 25.8452 1.56278 25.196C1.36699 24.5467 1.3747 23.8531 1.58489 23.2084C1.79509 22.5636 2.19767 21.9987 2.73851 21.5896C3.27935 21.1805 3.93249 20.9468 4.6101 20.92L20.0501 20.31C20.7095 20.2884 21.3474 20.0704 21.8819 19.6837C22.4164 19.2971 22.8232 18.7595 23.0501 18.14L28.4101 3.64001C28.645 3.00298 29.0695 2.45327 29.6265 2.065C30.1835 1.67673 30.8461 1.46857 31.5251 1.46857C32.2041 1.46857 32.8667 1.67673 33.4237 2.065C33.9807 2.45327 34.4052 3.00298 34.6401 3.64001L39.9901 18.14C40.2181 18.7588 40.6252 19.2955 41.1595 19.682C41.6938 20.0685 42.3311 20.2871 42.9901 20.31L58.4401 20.92C59.1169 20.9487 59.7687 21.1838 60.308 21.5936C60.8473 22.0035 61.2484 22.5684 61.4574 23.2128C61.6663 23.8571 61.6732 24.5499 61.4771 25.1983C61.281 25.8467 60.8912 26.4196 60.3601 26.84L48.2301 36.42C47.715 36.825 47.3316 37.3738 47.1287 37.9969C46.9258 38.62 46.9123 39.2893 47.0901 39.92L51.2801 54.8C51.4553 55.4475 51.4303 56.133 51.2083 56.7661C50.9863 57.3991 50.5777 57.95 50.0364 58.3463C49.4951 58.7425 48.8464 58.9654 48.1759 58.9857C47.5053 59.006 46.8444 58.8228 46.2801 58.46L33.4301 49.88C32.8734 49.4897 32.21 49.2804 31.5301 49.2804C30.8502 49.2804 30.1868 49.4897 29.6301 49.88Z" fill="white" stroke="black" stroke-miterlimit="10"/>
</svg>

<h3>0/15</h3>
</div>
<div id="yellow"></div>
<div id="pink"></div>
</div>


<style>
			#textContainer {
			  width: 180px;
			  display: flex;
			  justify-content: space-between;
			  align-items: center;
			  z-index: 2;
			}
			h3 {
			  /* z-index: 2; */
			  font-family: "Dela Gothic One", cursive;
			  font-weight: normal;
			  font-style: normal;
			  font-size: 30px;
			  /* line-height: 111px; */
			  letter-spacing: 0.01em;

			  color: black;
			}
			#scoreContainer {
			  position: absolute;
			  bottom: 50px;
			  left: 60px;
			  width: 250px;
			  height: 130px;
			  display: flex;
			  justify-content: center;
			  align-items: center;
			  /* cursor: pointer; */
			}
			#yellow {
			  position: fixed;
			  /* top: 0; */
			  width: 235px;
			  height: 100px;
			  background: #fef501;
			  border: 1px solid #000000;
			  border-radius: 20px;
			  z-index: 1;
			}
			#pink {
			  position: fixed;
			  /* top: 0; */
			  margin: 12px 0 0 15px;
			  width: 235px;
			  height: 100px;
			  background: #fef501;
			  border: 1px solid #000000;
			  border-radius: 20px;
			  z-index: 0;
			}
</style>