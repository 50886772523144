import { writable } from "svelte/store";

let vw;
let vh;
export let width = writable("");
export let height = writable("");

function resize() {
  vw = window.innerWidth / 100;
  vh = window.innerHeight / 100;
  width.set(vw * 100);
  height.set(vh * 100);
}
window.addEventListener("resize", resize);
resize();

export let language = writable("russian");

export let menuState = writable(true);

export let aboutScreenState = writable(false);
export let startScreenState = writable(true);
export let playerAvatarsScreenState = writable(false);
export let playerAvatar = writable("avatar1");
export let playerName = writable("Алиса");
export let howToPlayScreenState = writable(false);
export let mapScreenState = writable(false);
export let chapterScreenState = writable(false);
export let chapterOneState = writable(false);
export let chapterTwoState = writable(false);
export let chapterThreeState = writable(false);
export let chapterFourState = writable(false);
export let chapterFiveState = writable(false);
export let chapterSixState = writable(false);
export let dialogScreenState = writable(false);
export let dialogChapterTwoScreenState = writable(false);
export let dialogChapterThreeScreenState = writable(false);
export let dialogChapterFourScreenState = writable(false);
export let dialogChapterFiveScreenState = writable(false);
export let dialogChapterSixScreenState = writable(false);
export let sceneChapterOneState = writable(false);
export let sceneChapterTwoState = writable(false);
export let sceneChapterThreeState = writable(false);
export let sceneChapterFourState = writable(false);
export let sceneChapterFiveState = writable(false);
export let sceneChapterSixState = writable(false);
export let hangersActionOptionsScreenState = writable(false);
export let lightActionOptionsScreenState = writable(false);
export let wallActionOptionsScreenState = writable(false);
export let smellActionOptionsScreenState = writable(false);
export let wasteActionOptionsScreenState = writable(false);
export let congratsScreenState = writable(false);
export let chapterResultScreenState = writable(false);
export let robertScreenState = writable(false);
export let emailScreenState = writable(false);
export let finalDialogChapterOneScreenState = writable(false);
export let finalDialogChapterTwoScreenState = writable(false);
export let finalDialogChapterThreeScreenState = writable(false);
export let finalDialogChapterFourScreenState = writable(false);
export let finalDialogChapterFiveScreenState = writable(false);
export let finalDialogChapterSixScreenState = writable(false);
export let schoolTypesScreenState = writable(false);
export let schoolTypesScreenBackButtonState = writable(false);
export let finalScreenState = writable(false);
export let scrollWidth = writable("5px");

export let playSoundState = writable(true);

export let chapterOneSchoolResult = writable("персонализированной");
export let chapterOneSchoolResultDecisions = writable("");
export let chapterTwoSchoolResult = writable("персонализированной");
export let chapterTwoSchoolResultDecisions = writable("");
export let chapterThreeSchoolResult = writable("персонализированной");
export let chapterThreeSchoolResultDecisions = writable("");
export let chapterFourSchoolResult = writable("персонализированной");
export let chapterFourSchoolResultDecisions = writable("");
export let chapterFiveSchoolResult = writable("персонализированной");
export let chapterFiveSchoolResultDecisions = writable("");
export let chapterSixSchoolResult = writable("персонализированной");
export let chapterSixSchoolResultDecisions = writable("");
export let finalSchoolResult = writable("персонализированной");
export let finalSchoolScore = writable([
  [0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0]
]);

//locked, open, completed
export let chapterOneMapState = writable("open");
export let chapterTwoMapState = writable("locked");
export let chapterThreeMapState = writable("locked");
export let chapterFourMapState = writable("locked");
export let chapterFiveMapState = writable("locked");
export let chapterSixMapState = writable("locked");
