<script>
</script>

<div id="buttonContainer">
<h3>начать</h3>
<div id="yellow"></div>
<div id="pink"></div>
</div>

<style>
	h3 {
	  z-index: 2;
	  font-family: "Rubik Mono One", cursive;
	  font-weight: normal;
	  font-style: normal;
	  font-size: 90px;
	  line-height: 111px;
	  letter-spacing: 0.01em;
	  color: #ff5fb4;
	  text-shadow: 1px 1px 0 black, -1px 1px 0 black, 1px -1px 0 black,
	    -1px -1px 0 black;
	}
	#buttonContainer {
	  width: 700px;
	  height: 180px;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  cursor: pointer;
	}
	#yellow {
	  position: fixed;
	  /* top: 0; */
	  width: 685px;
	  height: 175px;
	  background: #fef501;
	  border: 1px solid #000000;
	  border-radius: 10px;
	  z-index: 1;
	}
	#pink {
	  position: fixed;
	  /* top: 0; */
	  margin: 12px 0 0 15px;
	  width: 685px;
	  height: 175px;
	  background: #ff5fb4;
	  border: 1px solid #000000;
	  border-radius: 10px;
	  z-index: 0;
	}
</style>